// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-board-of-directors-js": () => import("./../../../src/pages/about/board-of-directors.js" /* webpackChunkName: "component---src-pages-about-board-of-directors-js" */),
  "component---src-pages-about-consumer-advisory-council-js": () => import("./../../../src/pages/about/consumer-advisory-council.js" /* webpackChunkName: "component---src-pages-about-consumer-advisory-council-js" */),
  "component---src-pages-about-discipline-and-appeals-committees-js": () => import("./../../../src/pages/about/discipline-and-appeals-committees.js" /* webpackChunkName: "component---src-pages-about-discipline-and-appeals-committees-js" */),
  "component---src-pages-about-governance-compensation-disclosure-js": () => import("./../../../src/pages/about/governance/compensation-disclosure.js" /* webpackChunkName: "component---src-pages-about-governance-compensation-disclosure-js" */),
  "component---src-pages-about-governance-js": () => import("./../../../src/pages/about/governance.js" /* webpackChunkName: "component---src-pages-about-governance-js" */),
  "component---src-pages-about-industry-advisory-council-js": () => import("./../../../src/pages/about/industry-advisory-council.js" /* webpackChunkName: "component---src-pages-about-industry-advisory-council-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-leadership-js": () => import("./../../../src/pages/about/leadership.js" /* webpackChunkName: "component---src-pages-about-leadership-js" */),
  "component---src-pages-about-our-role-js": () => import("./../../../src/pages/about/our-role.js" /* webpackChunkName: "component---src-pages-about-our-role-js" */),
  "component---src-pages-about-research-and-education-program-consumer-education-resource-hub-js": () => import("./../../../src/pages/about/research-and-education-program/consumer-education-resource-hub.js" /* webpackChunkName: "component---src-pages-about-research-and-education-program-consumer-education-resource-hub-js" */),
  "component---src-pages-about-research-and-education-program-js": () => import("./../../../src/pages/about/research-and-education-program.js" /* webpackChunkName: "component---src-pages-about-research-and-education-program-js" */),
  "component---src-pages-about-research-and-education-program-research-education-resource-hub-js": () => import("./../../../src/pages/about/research-and-education-program/research-education-resource-hub.js" /* webpackChunkName: "component---src-pages-about-research-and-education-program-research-education-resource-hub-js" */),
  "component---src-pages-about-research-and-education-program-technical-research-and-education-resource-hub-2024-ontario-building-code-updates-js": () => import("./../../../src/pages/about/research-and-education-program/technical-research-and-education-resource-hub/2024-ontario-building-code-updates.js" /* webpackChunkName: "component---src-pages-about-research-and-education-program-technical-research-and-education-resource-hub-2024-ontario-building-code-updates-js" */),
  "component---src-pages-about-research-and-education-program-technical-research-and-education-resource-hub-js": () => import("./../../../src/pages/about/research-and-education-program/technical-research-and-education-resource-hub.js" /* webpackChunkName: "component---src-pages-about-research-and-education-program-technical-research-and-education-resource-hub-js" */),
  "component---src-pages-about-research-and-education-program-technical-research-and-education-resource-hub-upcoming-educational-offerings-js": () => import("./../../../src/pages/about/research-and-education-program/technical-research-and-education-resource-hub/upcoming-educational-offerings.js" /* webpackChunkName: "component---src-pages-about-research-and-education-program-technical-research-and-education-resource-hub-upcoming-educational-offerings-js" */),
  "component---src-pages-about-useful-links-js": () => import("./../../../src/pages/about/useful-links.js" /* webpackChunkName: "component---src-pages-about-useful-links-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-builder-vendor-become-a-licensed-builder-after-licensing-js": () => import("./../../../src/pages/builder-vendor/become-a-licensed-builder/after-licensing.js" /* webpackChunkName: "component---src-pages-builder-vendor-become-a-licensed-builder-after-licensing-js" */),
  "component---src-pages-builder-vendor-become-a-licensed-builder-after-your-application-is-submitted-js": () => import("./../../../src/pages/builder-vendor/become-a-licensed-builder/after-your-application-is-submitted.js" /* webpackChunkName: "component---src-pages-builder-vendor-become-a-licensed-builder-after-your-application-is-submitted-js" */),
  "component---src-pages-builder-vendor-become-a-licensed-builder-applying-for-a-licence-js": () => import("./../../../src/pages/builder-vendor/become-a-licensed-builder/applying-for-a-licence.js" /* webpackChunkName: "component---src-pages-builder-vendor-become-a-licensed-builder-applying-for-a-licence-js" */),
  "component---src-pages-builder-vendor-become-a-licensed-builder-before-you-apply-js": () => import("./../../../src/pages/builder-vendor/become-a-licensed-builder/before-you-apply.js" /* webpackChunkName: "component---src-pages-builder-vendor-become-a-licensed-builder-before-you-apply-js" */),
  "component---src-pages-builder-vendor-become-a-licensed-builder-do-i-need-a-licence-js": () => import("./../../../src/pages/builder-vendor/become-a-licensed-builder/do-i-need-a-licence.js" /* webpackChunkName: "component---src-pages-builder-vendor-become-a-licensed-builder-do-i-need-a-licence-js" */),
  "component---src-pages-builder-vendor-competency-requirements-js": () => import("./../../../src/pages/builder-vendor/competency-requirements.js" /* webpackChunkName: "component---src-pages-builder-vendor-competency-requirements-js" */),
  "component---src-pages-builder-vendor-complaints-compliance-enforcement-administrative-penalty-js": () => import("./../../../src/pages/builder-vendor/complaints-compliance-enforcement/administrative-penalty.js" /* webpackChunkName: "component---src-pages-builder-vendor-complaints-compliance-enforcement-administrative-penalty-js" */),
  "component---src-pages-builder-vendor-complaints-compliance-enforcement-code-of-ethics-js": () => import("./../../../src/pages/builder-vendor/complaints-compliance-enforcement/code-of-ethics.js" /* webpackChunkName: "component---src-pages-builder-vendor-complaints-compliance-enforcement-code-of-ethics-js" */),
  "component---src-pages-builder-vendor-complaints-compliance-enforcement-complaints-js": () => import("./../../../src/pages/builder-vendor/complaints-compliance-enforcement/complaints.js" /* webpackChunkName: "component---src-pages-builder-vendor-complaints-compliance-enforcement-complaints-js" */),
  "component---src-pages-builder-vendor-complaints-compliance-enforcement-compliance-enforcement-js": () => import("./../../../src/pages/builder-vendor/complaints-compliance-enforcement/compliance-enforcement.js" /* webpackChunkName: "component---src-pages-builder-vendor-complaints-compliance-enforcement-compliance-enforcement-js" */),
  "component---src-pages-builder-vendor-complaints-compliance-enforcement-licence-appeal-tribunal-js": () => import("./../../../src/pages/builder-vendor/complaints-compliance-enforcement/licence-appeal-tribunal.js" /* webpackChunkName: "component---src-pages-builder-vendor-complaints-compliance-enforcement-licence-appeal-tribunal-js" */),
  "component---src-pages-builder-vendor-complaints-compliance-enforcement-potential-registrar-actions-js": () => import("./../../../src/pages/builder-vendor/complaints-compliance-enforcement/potential-registrar-actions.js" /* webpackChunkName: "component---src-pages-builder-vendor-complaints-compliance-enforcement-potential-registrar-actions-js" */),
  "component---src-pages-builder-vendor-dashboard-js": () => import("./../../../src/pages/builder-vendor/dashboard.js" /* webpackChunkName: "component---src-pages-builder-vendor-dashboard-js" */),
  "component---src-pages-builder-vendor-faq-js": () => import("./../../../src/pages/builder-vendor/faq.js" /* webpackChunkName: "component---src-pages-builder-vendor-faq-js" */),
  "component---src-pages-builder-vendor-new-application-checklist-js": () => import("./../../../src/pages/builder-vendor/new-application-checklist.js" /* webpackChunkName: "component---src-pages-builder-vendor-new-application-checklist-js" */),
  "component---src-pages-builder-vendor-renewal-application-checklist-js": () => import("./../../../src/pages/builder-vendor/renewal-application-checklist.js" /* webpackChunkName: "component---src-pages-builder-vendor-renewal-application-checklist-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-go-js": () => import("./../../../src/pages/go.js" /* webpackChunkName: "component---src-pages-go-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-licensing-compliance-complaints-process-js": () => import("./../../../src/pages/licensing-compliance/complaints-process.js" /* webpackChunkName: "component---src-pages-licensing-compliance-complaints-process-js" */),
  "component---src-pages-licensing-compliance-directives-advisories-js": () => import("./../../../src/pages/licensing-compliance/directives-advisories.js" /* webpackChunkName: "component---src-pages-licensing-compliance-directives-advisories-js" */),
  "component---src-pages-licensing-compliance-file-a-complaint-js": () => import("./../../../src/pages/licensing-compliance/file-a-complaint.js" /* webpackChunkName: "component---src-pages-licensing-compliance-file-a-complaint-js" */),
  "component---src-pages-licensing-compliance-how-to-file-a-complaint-js": () => import("./../../../src/pages/licensing-compliance/how-to-file-a-complaint.js" /* webpackChunkName: "component---src-pages-licensing-compliance-how-to-file-a-complaint-js" */),
  "component---src-pages-licensing-compliance-illegal-building-complaint-js": () => import("./../../../src/pages/licensing-compliance/illegal-building-complaint.js" /* webpackChunkName: "component---src-pages-licensing-compliance-illegal-building-complaint-js" */),
  "component---src-pages-licensing-compliance-licensed-builder-complaint-js": () => import("./../../../src/pages/licensing-compliance/licensed-builder-complaint.js" /* webpackChunkName: "component---src-pages-licensing-compliance-licensed-builder-complaint-js" */),
  "component---src-pages-licensing-compliance-licensing-js": () => import("./../../../src/pages/licensing-compliance/licensing.js" /* webpackChunkName: "component---src-pages-licensing-compliance-licensing-js" */),
  "component---src-pages-licensing-compliance-possible-courses-of-action-js": () => import("./../../../src/pages/licensing-compliance/possible-courses-of-action.js" /* webpackChunkName: "component---src-pages-licensing-compliance-possible-courses-of-action-js" */),
  "component---src-pages-licensing-compliance-regulatory-activites-enforcement-charges-and-convictions-js": () => import("./../../../src/pages/licensing-compliance/regulatory-activites-enforcement/charges-and-convictions.js" /* webpackChunkName: "component---src-pages-licensing-compliance-regulatory-activites-enforcement-charges-and-convictions-js" */),
  "component---src-pages-licensing-compliance-regulatory-activites-enforcement-disclipline-appeals-js": () => import("./../../../src/pages/licensing-compliance/regulatory-activites-enforcement/disclipline-appeals.js" /* webpackChunkName: "component---src-pages-licensing-compliance-regulatory-activites-enforcement-disclipline-appeals-js" */),
  "component---src-pages-licensing-compliance-regulatory-activites-enforcement-js": () => import("./../../../src/pages/licensing-compliance/regulatory-activites-enforcement.js" /* webpackChunkName: "component---src-pages-licensing-compliance-regulatory-activites-enforcement-js" */),
  "component---src-pages-licensing-compliance-regulatory-activites-enforcement-notices-and-orders-js": () => import("./../../../src/pages/licensing-compliance/regulatory-activites-enforcement/notices-and-orders.js" /* webpackChunkName: "component---src-pages-licensing-compliance-regulatory-activites-enforcement-notices-and-orders-js" */),
  "component---src-pages-new-home-buyer-before-buying-a-preconstruction-condo-js": () => import("./../../../src/pages/new-home-buyer/before-buying-a-preconstruction-condo.js" /* webpackChunkName: "component---src-pages-new-home-buyer-before-buying-a-preconstruction-condo-js" */),
  "component---src-pages-new-home-buyer-dashboard-js": () => import("./../../../src/pages/new-home-buyer/dashboard.js" /* webpackChunkName: "component---src-pages-new-home-buyer-dashboard-js" */),
  "component---src-pages-new-home-buyer-expectations-for-your-builder-js": () => import("./../../../src/pages/new-home-buyer/expectations-for-your-builder.js" /* webpackChunkName: "component---src-pages-new-home-buyer-expectations-for-your-builder-js" */),
  "component---src-pages-new-home-buyer-newsletter-js": () => import("./../../../src/pages/new-home-buyer/newsletter.js" /* webpackChunkName: "component---src-pages-new-home-buyer-newsletter-js" */),
  "component---src-pages-new-home-buyer-steps-in-home-buyers-journey-step-1-js": () => import("./../../../src/pages/new-home-buyer/steps-in-home-buyers-journey/step-1.js" /* webpackChunkName: "component---src-pages-new-home-buyer-steps-in-home-buyers-journey-step-1-js" */),
  "component---src-pages-new-home-buyer-steps-in-home-buyers-journey-step-2-js": () => import("./../../../src/pages/new-home-buyer/steps-in-home-buyers-journey/step-2.js" /* webpackChunkName: "component---src-pages-new-home-buyer-steps-in-home-buyers-journey-step-2-js" */),
  "component---src-pages-new-home-buyer-steps-in-home-buyers-journey-step-3-js": () => import("./../../../src/pages/new-home-buyer/steps-in-home-buyers-journey/step-3.js" /* webpackChunkName: "component---src-pages-new-home-buyer-steps-in-home-buyers-journey-step-3-js" */),
  "component---src-pages-new-home-buyer-steps-in-home-buyers-journey-step-4-js": () => import("./../../../src/pages/new-home-buyer/steps-in-home-buyers-journey/step-4.js" /* webpackChunkName: "component---src-pages-new-home-buyer-steps-in-home-buyers-journey-step-4-js" */),
  "component---src-pages-new-home-buyer-steps-in-home-buyers-journey-step-5-js": () => import("./../../../src/pages/new-home-buyer/steps-in-home-buyers-journey/step-5.js" /* webpackChunkName: "component---src-pages-new-home-buyer-steps-in-home-buyers-journey-step-5-js" */),
  "component---src-pages-new-home-buyer-steps-in-home-buyers-journey-step-6-js": () => import("./../../../src/pages/new-home-buyer/steps-in-home-buyers-journey/step-6.js" /* webpackChunkName: "component---src-pages-new-home-buyer-steps-in-home-buyers-journey-step-6-js" */),
  "component---src-pages-new-home-buyer-working-with-your-builder-js": () => import("./../../../src/pages/new-home-buyer/working-with-your-builder.js" /* webpackChunkName: "component---src-pages-new-home-buyer-working-with-your-builder-js" */),
  "component---src-pages-news-and-events-backgrounders-js": () => import("./../../../src/pages/news-and-events/backgrounders.js" /* webpackChunkName: "component---src-pages-news-and-events-backgrounders-js" */),
  "component---src-pages-news-and-events-events-js": () => import("./../../../src/pages/news-and-events/events.js" /* webpackChunkName: "component---src-pages-news-and-events-events-js" */),
  "component---src-pages-news-and-events-js": () => import("./../../../src/pages/news-and-events.js" /* webpackChunkName: "component---src-pages-news-and-events-js" */),
  "component---src-pages-news-and-events-media-inquiries-js": () => import("./../../../src/pages/news-and-events/media-inquiries.js" /* webpackChunkName: "component---src-pages-news-and-events-media-inquiries-js" */),
  "component---src-pages-news-and-events-news-releases-js": () => import("./../../../src/pages/news-and-events/news-releases.js" /* webpackChunkName: "component---src-pages-news-and-events-news-releases-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-ttc-js": () => import("./../../../src/pages/ttc.js" /* webpackChunkName: "component---src-pages-ttc-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-backgrounder-js": () => import("./../../../src/templates/backgrounder.js" /* webpackChunkName: "component---src-templates-backgrounder-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

